import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { Web3Provider } from '@ethersproject/providers'


interface AddNetworkArguments {
  library: Web3Provider
}

// provider.request returns Promise<any>, but wallet_switchEthereumChain must return null or throw
// see https://github.com/rekmarks/EIPs/blob/3326-create/EIPS/eip-3326.md for more info on wallet_switchEthereumChain
export async function addNetwork({ library }: AddNetworkArguments): Promise<null | void> {
  if (!library?.provider?.request) {
    return
  }
  const formattedChainId = hexStripZeros(BigNumber.from(2016).toHexString())
  try {
    await library?.provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: formattedChainId,
          chainName: 'MainnetZ Mainnet Network',
          rpcUrls: 'https://mainnet-rpc.mainnetz.io',
          nativeCurrency: 'NETZ',
          blockExplorerUrls: ['https://explorer.mainnetz.io/'],
        },
      ],
    })
  } catch (error) {
    console.error('error adding mainnetz network: ', 2016, error)
  }
}
