import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { Web3Provider } from '@ethersproject/providers'


import { addNetwork } from './addNetwork'

interface SwitchNetworkArguments {
  library: Web3Provider | undefined 
}

// provider.request returns Promise<any>, but wallet_switchEthereumChain must return null or throw
// see https://github.com/rekmarks/EIPs/blob/3326-create/EIPS/eip-3326.md for more info on wallet_switchEthereumChain
export async function switchToNetwork({ library }: SwitchNetworkArguments): Promise<null | void> {

  let chainId =2016;

 
  if (!library?.provider?.request) {

    return
  }
  if (!chainId && library?.getNetwork) {
    ;({ chainId } = await library.getNetwork())
  }


  const formattedChainId = hexStripZeros(BigNumber.from(2016).toHexString())
  try {


    // alert(JSON.stringify(await library?.provider))

    await library?.provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: formattedChainId }],
    })


  
   
  } catch (error) {
    // 4902 is the error code for attempting to switch to an unrecognized chainId

 
    if (error.code === 4902 ) {

      // alert(JSON.stringify(error))
     
      // metamask (only known implementer) automatically switches after a network is added
      // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
      // metamask's behavior when switching to the current network is just to return null (a no-op)
      await addNetwork({ library })
      await switchToNetwork({ library })
    } else {

     
      //throw error
      
    }
  }
}
