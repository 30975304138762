
import { ChainId, WETH } from '@uniswap/sdk'
import { useEffect } from 'react';


// import { Web3Provider } from '@ethersproject/providers'

import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'


import { useMultipleContractSingleData } from '../../state/multicall/hooks'

import Web3 from 'web3';

import {  ROUTER_ADDRESS, USDT ,ETH,BNB,MATIC} from '../../constants'



function setCookie(name : any, value : any, seconds : any) {
    var expires = "";
    if (seconds) {
      var date = new Date();
      date.setTime(date.getTime() + (seconds));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  
  function getCookie(name: any) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }



  function getPriceAPI(tokenID:any){

        return `https://api.coingecko.com/api/v3/simple/price?ids=${tokenID}&vs_currencies=usd`
  }


export async function GetCurrencyUsdPrice(){
    
    const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

    const web3 = new Web3(String(NETWORK_URL))

   

    let ethPrice;
    let bnbPrice;
    let maticPrice;
    let aiObject;

    // validate cookies

    const _cookies = getCookie("ethPriceInUsd")

    if(_cookies==null){

        // cookies doesn't exisit set new one

         aiObject= await fetch(getPriceAPI('ethereum'))
         ethPrice = await aiObject.json()
         ethPrice = (ethPrice['ethereum']['usd'])


        aiObject= await fetch(getPriceAPI('binancecoin'))
         bnbPrice = await aiObject.json()
         bnbPrice = (bnbPrice['binancecoin']['usd'])

         aiObject= await fetch(getPriceAPI('matic-network'))
         maticPrice = await aiObject.json()
         maticPrice = (maticPrice['matic-network']['usd'])


        setCookie("ethPriceInUsd", ethPrice, 1800000) // 30 minute expiry
        setCookie("bnbPriceInUsd", bnbPrice, 1800000) // 30 minute expiry
        setCookie("maticPriceInUsd", maticPrice, 1800000) // 30 minute expiry



    }else{

        // cookies exisit use price from here

        ethPrice= getCookie("ethPriceInUsd");
        bnbPrice = getCookie("bnbPriceInUsd");
        maticPrice= getCookie("maticPriceInUsd");

    }

    let currencyUsd = " "

    try {

        const router_call = new web3.eth.Contract(
            
            [
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "_factory",
                            "type": "address"
                        },
                        {
                            "internalType": "address",
                            "name": "_WETH",
                            "type": "address"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "constructor"
                },
                {
                    "inputs": [],
                    "name": "WETH",
                    "outputs": [
                        {
                            "internalType": "address",
                            "name": "",
                            "type": "address"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "tokenA",
                            "type": "address"
                        },
                        {
                            "internalType": "address",
                            "name": "tokenB",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountADesired",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountBDesired",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountAMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountBMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "addLiquidity",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountA",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountB",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "liquidity",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "token",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountTokenDesired",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountTokenMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountETHMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "addLiquidityETH",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountToken",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountETH",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "liquidity",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "payable",
                    "type": "function"
                },
                {
                    "inputs": [],
                    "name": "factory",
                    "outputs": [
                        {
                            "internalType": "address",
                            "name": "",
                            "type": "address"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountOut",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "reserveIn",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "reserveOut",
                            "type": "uint256"
                        }
                    ],
                    "name": "getAmountIn",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountIn",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "pure",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountIn",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "reserveIn",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "reserveOut",
                            "type": "uint256"
                        }
                    ],
                    "name": "getAmountOut",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountOut",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "pure",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountOut",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        }
                    ],
                    "name": "getAmountsIn",
                    "outputs": [
                        {
                            "internalType": "uint256[]",
                            "name": "amounts",
                            "type": "uint256[]"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountIn",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        }
                    ],
                    "name": "getAmountsOut",
                    "outputs": [
                        {
                            "internalType": "uint256[]",
                            "name": "amounts",
                            "type": "uint256[]"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountA",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "reserveA",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "reserveB",
                            "type": "uint256"
                        }
                    ],
                    "name": "quote",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountB",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "pure",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "tokenA",
                            "type": "address"
                        },
                        {
                            "internalType": "address",
                            "name": "tokenB",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "liquidity",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountAMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountBMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "removeLiquidity",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountA",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountB",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "token",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "liquidity",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountTokenMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountETHMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "removeLiquidityETH",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountToken",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountETH",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "token",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "liquidity",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountTokenMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountETHMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "removeLiquidityETHSupportingFeeOnTransferTokens",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountETH",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "token",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "liquidity",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountTokenMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountETHMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        },
                        {
                            "internalType": "bool",
                            "name": "approveMax",
                            "type": "bool"
                        },
                        {
                            "internalType": "uint8",
                            "name": "v",
                            "type": "uint8"
                        },
                        {
                            "internalType": "bytes32",
                            "name": "r",
                            "type": "bytes32"
                        },
                        {
                            "internalType": "bytes32",
                            "name": "s",
                            "type": "bytes32"
                        }
                    ],
                    "name": "removeLiquidityETHWithPermit",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountToken",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountETH",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "token",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "liquidity",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountTokenMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountETHMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        },
                        {
                            "internalType": "bool",
                            "name": "approveMax",
                            "type": "bool"
                        },
                        {
                            "internalType": "uint8",
                            "name": "v",
                            "type": "uint8"
                        },
                        {
                            "internalType": "bytes32",
                            "name": "r",
                            "type": "bytes32"
                        },
                        {
                            "internalType": "bytes32",
                            "name": "s",
                            "type": "bytes32"
                        }
                    ],
                    "name": "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountETH",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "tokenA",
                            "type": "address"
                        },
                        {
                            "internalType": "address",
                            "name": "tokenB",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "liquidity",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountAMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountBMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        },
                        {
                            "internalType": "bool",
                            "name": "approveMax",
                            "type": "bool"
                        },
                        {
                            "internalType": "uint8",
                            "name": "v",
                            "type": "uint8"
                        },
                        {
                            "internalType": "bytes32",
                            "name": "r",
                            "type": "bytes32"
                        },
                        {
                            "internalType": "bytes32",
                            "name": "s",
                            "type": "bytes32"
                        }
                    ],
                    "name": "removeLiquidityWithPermit",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountA",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountB",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountOut",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapETHForExactTokens",
                    "outputs": [
                        {
                            "internalType": "uint256[]",
                            "name": "amounts",
                            "type": "uint256[]"
                        }
                    ],
                    "stateMutability": "payable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountOutMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapExactETHForTokens",
                    "outputs": [
                        {
                            "internalType": "uint256[]",
                            "name": "amounts",
                            "type": "uint256[]"
                        }
                    ],
                    "stateMutability": "payable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountOutMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapExactETHForTokensSupportingFeeOnTransferTokens",
                    "outputs": [],
                    "stateMutability": "payable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountIn",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountOutMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapExactTokensForETH",
                    "outputs": [
                        {
                            "internalType": "uint256[]",
                            "name": "amounts",
                            "type": "uint256[]"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountIn",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountOutMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapExactTokensForETHSupportingFeeOnTransferTokens",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountIn",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountOutMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapExactTokensForTokens",
                    "outputs": [
                        {
                            "internalType": "uint256[]",
                            "name": "amounts",
                            "type": "uint256[]"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountIn",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountOutMin",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapExactTokensForTokensSupportingFeeOnTransferTokens",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountOut",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountInMax",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapTokensForExactETH",
                    "outputs": [
                        {
                            "internalType": "uint256[]",
                            "name": "amounts",
                            "type": "uint256[]"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amountOut",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amountInMax",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address[]",
                            "name": "path",
                            "type": "address[]"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "deadline",
                            "type": "uint256"
                        }
                    ],
                    "name": "swapTokensForExactTokens",
                    "outputs": [
                        {
                            "internalType": "uint256[]",
                            "name": "amounts",
                            "type": "uint256[]"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "stateMutability": "payable",
                    "type": "receive"
                }
            ]

            ,ROUTER_ADDRESS);
    

        let allPairUsdPrice;


        // fetch price fro eth pair

        let usdcPrice = await router_call.methods.getAmountsOut("1000000000000000000",[WETH[ChainId.MAINNET].address,ETH.address]).call()
    
        let calPrice  = (usdcPrice[1])/1e18
        // call the api to fetch same amount of tokens in usd value 
        calPrice= calPrice*ethPrice;
        allPairUsdPrice=calPrice; // eth price 

        // alert("eth :"+(allPairUsdPrice).toFixed(4))

        // fetch price from bnb pair

        usdcPrice = await router_call.methods.getAmountsOut("1000000000000000000",[WETH[ChainId.MAINNET].address,BNB.address]).call() // need to change pair
        
        calPrice  = (usdcPrice[1])/1e18

        calPrice= calPrice*bnbPrice;

        // alert("bnb :"+(calPrice).toFixed(4))

        allPairUsdPrice+=calPrice; // bnb price 


        // fetch price from matic pair

        usdcPrice = await router_call.methods.getAmountsOut("1000000000000000000",[WETH[ChainId.MAINNET].address,MATIC.address]).call() // need to change pair
        
        calPrice  = (usdcPrice[1])/1e18
        calPrice= calPrice*maticPrice;

        // alert("matic :"+(calPrice).toFixed(4))

        allPairUsdPrice+=calPrice; // matic price 

        allPairUsdPrice=allPairUsdPrice/3;

        currencyUsd= (allPairUsdPrice).toFixed(4);


    }catch(error){


    }

    return (currencyUsd)

}